<template>



    <div class="hideOnMob row nb2">

        <div class="col-auto">
            <a class="navbar-brand" href="/">
                <div class="logo nav-365-logo my-2" style="width:auto;height:auto;">
                    <img style="width:146px; height:57.56px; position: unset;"
                        src="/assets/img/logos/switch365-energy-comparison-website.svg"
                        alt="switch365-energy-comparison-website" />

                </div>
            </a>
        </div>

        <!-- position:relative;  -->
        <!-- display: flex; -->
        <div class="col">
            <!-- here -->

            <!-- align-items-center -->
            <!-- flex align-items-center justify-space-around -->

            <ul class="top-level-menu mainMenuContainer  " >
                <li ><a  href="/">Home</a></li>
                <li  ><a href="/aboutus/">About Us</a></li>
                <li  ><a href="/contactus/">Contact</a></li>
                <li style="position: relative">
                    <a href="#">
                        Services
                        <label title="toggle menu" for="services">
                            <i class="fa fa-caret-down"></i>
                        </label>
                    </a>
                    <ul class="second-level-menu">
                        <li ><a href="/services/business-energy/">Energy</a></li>
                        <li><a href="/services/church-energy/">Church Energy</a></li>
                        <li><a href="/services/business-electricity/">Electricity</a></li>
                        <li><a href="/services/business-gas/">Gas</a></li>
                        <li><a href="/services/business-water/">Water</a></li>
                        <li><a href="/services/card-terminals/">Terminals</a></li>
                        <li><a href="/services/merchant-cash-advance/">Cash Advance</a></li>

                        
                            
                    </ul>
                </li>
                <li style=" position: relative">
                    <a href="#">
                        Content Hub
                        <label title="toggle menu" for="services">
                            <i class="fa fa-caret-down"></i>
                        </label>
                    </a>
                    <ul class="second-level-menu">

                        <li><a href="/content-hub/business-utilities/">Articles</a></li>
                        <li>
                            <a href="/press-releases/">Press Releases</a>
                            <!-- <ul class="third-level-menu mcWide">
                                <li class=" "><a href="/press-releases/557-million-allocated-to-public-buildings">£557
                                        Million
                                        Allocated to Public Buildings</a></li>
                                <li class="mcWide"><a
                                        href="/press-releases/uk-leads-europe-in-next-gen-nuclear-fuel-investment/">UK
                                        Leads
                                        Europe in Next-Gen Nuclear Fuel Investment</a>
                                </li>
                                <li class=" "><a href="/press-releases/uk-factories-slip-back-into-downturn">UK
                                        Factories Slip
                                        Back into Downturn</a></li>

                            </ul> -->
                        </li>
                        <!-- <li><a href="#">Another Menu</a></li> -->
                    </ul>
                </li>
            </ul>

            <!-- end here -->
        </div>

        <div class="col-xl-auto xl-side-btn">
            <div class="btnContainer2" style="position:relative;">
                <button @mouseover="state.showHours = true" @mouseleave="state.showHours = false" id="call_now"
                    class="btn btn-primary btn-nav-sm dropdown-toggle call-now phoneBtn1"
                    style="font-size: 20px !important; margin-top:-5px;" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">Call: 0345 365 4 365</button>


                <div v-show="state.showHours" class=" p-4 boxy2">
                    <div class="container">
                        <div class="row">
                            <h4 class="text-center"
                                style="font-weight: 700; font-style: normal; color: rgb(140, 82, 255);">0345 365 4 365
                            </h4>
                            <h4 class="text-center">Opening hours</h4>
                        </div>
                        <div class="container" style="white-space: nowrap;">
                            <div class="row">
                                <div class="col mr-5">
                                    <div class="row">Monday - Thursday</div>
                                    <div class="row">Friday</div>
                                    <div class="row">Saturday - Sunday</div>
                                </div>
                                <div class="col">
                                    <div class="row">9:00am - 5:00pm</div>
                                    <div class="row">9:00am - 4:00pm</div>
                                    <div class="row">Closed</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- href="https://quote.switch365.com/journey/supply-details" -->

                <a href="/contactus/" class="jazzybtn">
                    Contact Us
                    <div class="jazzybtn2"></div>
                </a>

            


            </div>
        </div>

        <div class="col-xl-auto xl-side-btn lg-side-btn">
            <div class="btnContainer2" style="position:relative;">
                <button @mouseover="state.showHours = true" @mouseleave="state.showHours = false" id="call_now"
                    class="btn btn-primary btn-nav-sm dropdown-toggle call-now phoneBtn1"
                    style="font-size: 20px !important; margin-top:-5px;" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">Call</button>


                <div v-show="state.showHours" class=" p-4 boxy2lg">
                    <div class="container">
                        <div class="row">
                            <h4 class="text-center"
                                style="font-weight: 700; font-style: normal; color: rgb(140, 82, 255);">0345 365 4 365
                            </h4>
                            <h4 class="text-center">Opening hours</h4>
                        </div>
                        <div class="container" style="white-space: nowrap;">
                            <div class="row">
                                <div class="col mr-5">
                                    <div class="row">Monday - Thursday</div>
                                    <div class="row">Friday</div>
                                    <div class="row">Saturday - Sunday</div>
                                </div>
                                <div class="col">
                                    <div class="row">9:00am - 5:00pm</div>
                                    <div class="row">9:00am - 4:00pm</div>
                                    <div class="row">Closed</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <a href="https://quote.switch365.com/journey/supply-details" class="jazzybtn3">
                    Quote
                    <div class="jazzybtn2"></div>

                </a> -->

            


            </div>
        </div>

    </div>

    <div class="mobOnly">
        <nav role="navigation" class="menu nb2Mob">
            <div class="row">
                
                <div style="position:relative; height:80px;" class="col-12">
                    <a href="/"> 
                        <img style="width:106px; height:auto; position:absolute; top:20px; left:20px;"
                            src="/assets/img/logos/switch365-energy-comparison-website.svg"
                            alt="switch365-energy-comparison-website" />
                    </a>
                    <div style="font-size: 20px !important; position:absolute; height:85px; top:0px; right:20px; width:150px;">
                        <a href="tel:03453654365" style="float:left; height:100%; padding:30px 5px 0px 5px;" aria-label="Call Switch 365">
                            <span class="fa fa-phone"></span>
                        </a>
                        <span style="padding:5px;">|</span>
                        <label class="float-left" for="menu" style="margin:24px 10px 0px 0px;">Menu <i class="fa fa-bars"></i></label>
                    </div>
                </div>
            </div> 
            <input type="checkbox" id="menu" />
            <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/aboutus/">About Us</a></li>
                <li><a href="/contactus">Contact</a></li>
                <li class="menu-hasdropdown">
                    <a href="#">Services
                        <label title="toggle menu" for="services">
                            <i class="fa fa-caret-down"></i>
                        </label>
                    </a>
                    <input type="checkbox" id="services" />
                    <ul class="menu-dropdown">
                        <li><a href="/services/business-energy/">Energy</a></li>
                        <li><a href="/services/church-energy/">Church Energy</a></li>
                        <li><a href="/services/business-electricity/">Electricity</a></li>
                        <li><a href="/services/business-gas/">Gas</a></li>
                        <li><a href="/services/business-water/">Water</a></li>
                        <li><a href="/services/card-terminals/">Terminals</a></li>
                        <li><a href="/services/merchant-cash-advance/">Cash Advance</a></li>
                    </ul>
                </li>
                <li class="menu-hasdropdown">
                    <a href="#">Content Hub
                        <label title="toggle menu" for="contentHub">
                            <i class="fa fa-caret-down"></i>
                        </label>
                    </a>
                    <input type="checkbox" id="contentHub" />
                    <ul class="menu-dropdown">
                        <li><a href="/content-hub/business-utilities/">Articles</a></li>
                        <li class="menu-hasdropdown menu-hasflyout">
                            <a href="">Press Releases
                                <label title="toggle menu" for="pressreleases">
                                    <i class="fa fa-caret-down menu-downicon"></i>
                                </label>
                            </a>
                            <input type="checkbox" id="pressreleases" />
                            <ul class="menu-dropdown">
                                <li class=" "><a href="/press-releases/557-million-allocated-to-public-buildings">£557 Million Allocated to Public Buildings</a></li>
                                <li class="mcWide"><a href="/press-releases/uk-leads-europe-in-next-gen-nuclear-fuel-investment/">UK Leads Europe in Next-Gen Nuclear Fuel Investment</a></li>
                                <li class=" "><a href="/press-releases/uk-factories-slip-back-into-downturn">UK Factories Slip Back into Downturn</a></li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>

    <div class="mob-m"></div>


</template>

<script setup>
import { ref, reactive } from 'vue'
import NavbarContactForm from '../Contact/navbarContactForm.vue'

let state = reactive({
    showHours: false
});
</script>

<style>
/* Apply styles specifically for mobile view */
/* @media (max-width: 768px) {
    .nb2Mob {
        padding: 0 0 0 20px;
        background: linear-gradient(90deg, rgb(2, 0, 36) 0%, rgb(87, 50, 144) 35%, rgb(38, 191, 176) 100%) !important;
        position: fixed !important;
        top: 0 !important;
        width: 100%;
        z-index: 1000 !important;
    }
} */




/* main menu */

/* @keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-0.02px);
    }
}

.bouncing-arrow {
    animation: bounce 2s infinite;
}

.bouncing-arrow:hover {
    animation-play-state: paused;
} */

/* #normal-icon:hover + #hover-icon,
#hover-icon:hover {
    display: inline;
}

#normal-icon:hover,
#hover-icon:hover + #normal-icon {
    display: none;
} */

.xl-side-btn {
    display: none ;
    }
    .lg-side-btn {
        display: none ;
    }

.mainMenuContainer > li {
    margin-right: 1.5rem !important;

}




@media (min-width: 1651px) {

    .xl-side-btn {
    display: block ;
    }
    .lg-side-btn {
        display: none ;
    }
    
}

@media (min-width: 1200px) and (max-width: 1650px) {

    .xl-side-btn {
        display: none ;
    }

    .lg-side-btn {
        display: block ;
    }

}
</style>